.letterinfo {
  padding: 0.2rem 0.4rem;
}

.lettertitle {
  text-align: center;
  padding: 0.2rem 0 0.3rem;
  border-bottom: 0.01rem solid #e5e5e5;
}

.lettertitle h1 {
  font-size: 0.18rem;
  color: #333333;
  line-height: 1.333;
}

.lettertitle .time {
  font-size: 0.16rem;
  color: #888888;
  line-height: 1.75;
}

.content {
  padding: 0.2rem 0;
  min-height: 1.5rem;
}

.back {
  text-align: center;
  padding: 0.2rem 0;
}

.back /deep/ .ant-btn {
  min-width: 1.5rem;
  padding: 0 0.4rem;
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.3rem;
  background-color: #00ffc0;
  color: #000;
  font-size: 0.2rem;
  border-color: #00ffc0;
}

.back /deep/ .ant-btn:hover {
  background-color: #79f9d9;
  border-color: #79f9d9;
  box-shadow: 0 0 0.1rem 0 rgba(121, 249, 217, 0.8);
}